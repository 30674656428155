import React from "react";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router";
import dayJS from "dayjs";
import { Modal, Statistic, Table } from "antd";
import { getDistribution, getPlanification } from "../../API/StaticGetters";
import DistributionDetail from "./detail";
import PlanificationDetail from "./detail";
import { set } from "date-fns";


const UnitPlans = ({ dados, saldo }) => {
    const [planification, setPlanification] = useState({})
    const [modalReq, setModalReq] = useState(false)
    const [lastPlan, setLastPlan] = useState({})
    const [executionLevel, setExecutionLevel] = useState(0)

    useEffect(() => {
        setLastPlan(dados[0])
    }, [dados])

    const columnsPlan = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Valor total',
            dataIndex: 'plan',
            key: 'value',
            width: 200,
            render: (text, record) => (
                <p>{`MZN ${record.plan.reduce((acc, item) => acc + parseFloat(item.value), 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</p>
            )
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text, record) => (
                <p>{record.status.name}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 50,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleViewPlan(record)}>
                        <FaEye
                            size={17}
                            color={"#047d4b"}
                        />
                    </a>
                </span>
            ),
        },
    ];

    const totalArrecadado = () => {
        let total = 0
        dados[0]?.plan.map((item) => {
            total += parseFloat(item.value)
        })
        return total
    }

    const clcNivelExecucao = () => {
        let total = 0
        dados[0]?.plan.map((item) => {
            total += parseFloat(item.value)
        })
        if(parseInt(total)===parseInt(saldo)) return 0
        if (total === 0) return 0
        if (saldo <= 0) return 100
        let nivel = (saldo / total) * 100
        return nivel
    }

    const handleViewPlan = async (record) => {
        let dados = await getPlanification(record.id)
        setPlanification(dados)
        setModalReq(true)
    }

    return (
        <>
            <div className="w-full flex flex-row gap-6">
                <div className="w-1/4 flex flex-col">
                    <p className='title-form'>Última Distribuição</p>
                    <Statistic
                        title="Valor Planificado"
                        prefix="MZN"
                        value={totalArrecadado()}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        className="border border-gray-300 p-2 rounded-lg"
                    />
                    <Statistic
                        title="Nível de Execução"
                        prefix="%"
                        value={clcNivelExecucao()}
                        precision={3}
                        valueStyle={{ color: '#3f8600' }}
                        className="border border-gray-300 p-2 rounded-lg mt-3"
                    />
                </div>
                <div className="w-3/4 flex flex-col">
                    <p className='title-form'>Planificações</p>
                    <Table
                        className='custom-table'
                        size="small"
                        columns={columnsPlan}
                        dataSource={dados}
                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                        locale={{
                            emptyText: 'Sem distribuições'
                        }}
                    />
                    <Modal
                        title={<p className='title'>DETALHES DA PLANIFICAÇÃO</p>}
                        open={modalReq}
                        onOk={() => setModalReq(false)}
                        onCancel={() => setModalReq(false)}
                        footer={null}
                        width={700}
                    >
                        <PlanificationDetail dados={planification} />
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default UnitPlans