import axios from 'axios';
import { notification } from 'antd';

export const getProfilePicture = async () => {
    await axios.get(localStorage.getItem('url') + '/api/user/picture/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`,
            },
            responseType: 'blob'
        })
        .then(response => {
            sessionStorage.setItem('profile', URL.createObjectURL(response.data))
        }).catch(err => {
            
        }
        )
}

export const getProvinces = () => {
    const Provinces = [
        {
            id: '0',
            name: 'Maputo Cidade',
            country: 'Moçambique',
        },
        {
            id: '1',
            name: 'Maputo',
            country: 'Moçambique',
        },
        {
            id: '3',
            name: 'Gaza',
            country: 'Moçambique',
        },
        {
            id: '4',
            name: 'Inhambane',
            country: 'Moçambique',
        },
        {
            id: '5',
            name: 'Sofala',
            country: 'Moçambique',
        },
        {
            id: '6',
            name: 'Manica',
            country: 'Moçambique',
        },
        {
            id: '7',
            name: 'Tete',
            country: 'Moçambique',
        },
        {
            id: '8',
            name: 'Zambezia',
            country: 'Moçambique',
        },
        {
            id: '9',
            name: 'Nampula',
            country: 'Moçambique',
        },
        {
            id: '10',
            name: 'Cabo Delgado',
            country: 'Moçambique',
        },
        {
            id: '11',
            name: 'Niassa',
            country: 'Moçambique',
        },
    ]

    return Provinces;
}

export const getModeTransport = () => {
    const ModeTransport = [
        {
            id: '0',
            name: 'Rodoviário',
        },
        {
            id: '1',
            name: 'Ferroviário',
        },
        {
            id: '2',
            name: 'Aéreo',
        },
        {
            id: '3',
            name: 'Marítimo',
        },
        {
            id: '4',
            name: 'Fluvial',
        },
        {
            id: '5',
            name: 'Lacustre',
        },
        {
            id: '6',
            name: 'Cabos',
        },
        {
            id: '7',
            name: 'Tubagem',
        },
    ]

    return ModeTransport;

}

export const getUnits = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar estâncias'
            })
        })
    return dados;
}

export const getUnit = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar estância'
            })
        })
    return dados;
}


export const getStates = async () => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/organograma/states/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar estados'
            })
        })
    return dados;
}

export const getGroups = async (tipo) => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/role/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params: {
                type: tipo
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar grupos'
            })
        })
    return dados;
}

export const getUsers = async (tipo) => {
    console.log(tipo)
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/admin/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params: {
                type: tipo
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuários'
            })
        })
    return dados;
}

export const getUser = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/user/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuário'
            })
        })
    return dados;
}

export const getHistory = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/history/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
            console.log(res.data)
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar historico'
            })
        })
    return dados;
}


export const getExpenses = async (id,type, date) => {
    // 1 - Pendente
    // 2 - Aprovado
    // 4 - Autorizado

    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id,
                type:type,
                dates:date
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getUnitBalance = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/finance/balance/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getUnitMoviments = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/finance/moviment/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id,
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados.reverse();
}

export const getExpense = async (pk) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/' + pk + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getDistributions = async (status, unit) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status,
                unit:unit
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getDistribution = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getPlanifications = async (status, unit) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status,
                unit:unit
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getPlanification =async(id)=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}