import React from "react";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router";
import dayJS from "dayjs";
import { Modal, Table } from "antd";
import { getDistribution } from "../../API/StaticGetters";
import DistributionDetail from "./detail";


const UnitDists = ({ dados }) => {
    const [distribution, setDistribution] = useState({})
    const [modalView, setModalView] = useState(false)

    const columnsDist = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record.unit.name}</p>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'periodo',
            key: 'periodo',
            width: 200,
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text, record) => (
                <p>{record.status.name}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 50,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleView(record.id)}>
                        <FaEye
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                </span>
            ),
        },
    ];

    const handleView = async (id) => {
        let dados = await getDistribution(id)
        setDistribution(dados)
        setModalView(true)
    }

    return (
        <>
            <p className='title-form'>DISTRIBUIÇÕES</p>
            <Table
                className='custom-table'
                size="small"
                columns={columnsDist}
                dataSource={dados}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem distribuições'
                }}
            />
            <Modal
                title={<p className='title'>DETALHES DA DISTRIBUIÇÃO</p>}
                open={modalView}
                onOk={() => setModalView(false)}
                onCancel={() => setModalView(false)}
                footer={null}
                width={700}
            >
                <DistributionDetail dados={distribution} />
            </Modal>
        </>
    )
}

export default UnitDists;