import React, { useState, useEffect } from 'react';
import { Input, Table, notification, Divider, Modal, Button, Select, DatePicker, Statistic, Spin, List, Tag } from 'antd';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { getDistributions, getExpense, getExpenses, getPlanifications, getUnitBalance, getUnitMoviments, getUnits } from '../../API/StaticGetters';
import dayjs from 'dayjs';
import {
    Sector,
    LineChart,
    PieChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, BarChart, Bar, Pie, AreaChart, Area
} from "recharts";
import { FiTrendingUp, FiTrendingDown, FiEye } from 'react-icons/fi';
import numeral from 'numeral';
import axios from 'axios';
import ExpenseDetail from '../../Components/despesa/detail'

import { Option } from 'antd/es/mentions';
import { ca } from 'date-fns/locale';
import { data } from 'autoprefixer';
import UnitDists from '../../Components/Monitoramento';
import UnitPlans from '../../Components/planificacao';
import { set } from 'date-fns';

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.pagamento}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};


const Monitorar = (props) => {
    const [type, setType] = useState('unit')

    const [selectedMenu, setSelectedMenu] = useState('revenue')
    const [units, setUnits] = useState([])
    const [unitBalance, setUnitBalance] = useState()
    const [selectedUnit, setSelectedUnit] = useState(null)

    const currentDate = dayjs();
    const startDate = dayjs().subtract(7, 'day');
    const [endDate, setEndDate] = useState(currentDate);
    const [selectedDateRange, setSelectedDateRange] = useState([startDate, currentDate]);

    const handleDateChange = (dates) => {
        if (dates) {
            setSelectedDateRange(dates);
            setEndDate(dates[1]);
        }
    };

    useEffect(() => {
        localStorage.setItem('title', 'Monitoramento de Receitas Próprias');
        localStorage.setItem('type', '2');
        if ([8, 9, 7].includes(parseInt(sessionStorage.getItem('access')))) {
            setType('unit')
            setSelectedUnit(JSON.parse(sessionStorage.getItem('user')).unidade.id_sigf)
        } else {
            setType('all')
        }
        searchUnits();
    }, [])

    const searchUnits = async () => {
        const response = await getUnits();
        if (response) {
            setUnits(response);
        } else {
            notification.error({
                message: 'Erro',
                description: 'Erro ao buscar unidades',
            });
        }
    };

    //Receitas Info
    const [dataPaid, setDataPaid] = useState([]);
    const [dataNotPaid, setDataNotPaid] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typeCourse, setTypeCourse] = useState([]);
    const [typePropina, setTypePropina] = useState([]);
    const [typePayment, setTypePayment] = useState([]);
    const [selectedsCourse, setSelectedsCourse] = useState([]);
    const [selectedsPropina, setSelectedsPropina] = useState([]);
    const [selectedsPayment, setSelectedsPayment] = useState([]);

    const [totalRevenue, setTotalRevenue] = useState(0)

    const [dataByDate, setDataByDate] = useState([]);
    const [dataByCourse, setDataByCourse] = useState([]);
    const [dataByReceita, setDataByReceita] = useState([]);
    const [dataByPayment, setDataByPayment] = useState([]);

    const [dataExpense, setDataExpense] = useState([]);
    const [moviments, setMoviments] = useState([]);


    const getData = async (start, end) => {
        setLoading(true);
        setDataPaid([]);
        setDataNotPaid([]);
        try {
            axios({
                method: "get",
                url: `${localStorage.getItem('url')}/api/finance/receitas/${selectedUnit}/${(end).format('YYYY-MM-DD')}/${(start).format('YYYY-MM-DD')}/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
            }).then((res) => {
                setDataPaid(res.data.receita);
                findCourses(res.data.receita);
                findReceitas(res.data.receita);
                findPayments(res.data.receita);
                setLoading(false);
                setDataByDate(agruparPorDataPagamento(res.data.receita));
                setDataByCourse(agruporPorCurso(res.data.receita));
                setDataByReceita(agruparPorReceita(res.data.receita));
                setDataByPayment(agruparPorPagamento(res.data.receita));
                setTotalRevenue(res.data.receita.reduce((acc, row) => acc + row.valor, 0))
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                notification.error({
                    message: 'Erro',
                    description: 'Erro ao obter dados',
                });
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Erro ao obter dados',
            });
        }
        let blnc = await getUnitBalance(units.filter(unit => unit.id_sigf === selectedUnit)[0].id)
        setUnitBalance(blnc)
        console.log(blnc)
        let movs = await getUnitMoviments(units.filter(unit => unit.id_sigf === selectedUnit)[0].id)
        setMoviments(movs)
        let exps = await getExpenses(units.filter(unit => unit.id_sigf === selectedUnit)[0].id, 'All', `${(end).format('YYYY-MM-DD')} - ${(start).format('YYYY-MM-DD')}`)
        setDataExpense(exps)
        setTableExpense(exps)
        findTypeExpense(exps)
        setExpensesByType(agruparExpenseByType(exps))
        setExpensesByDate(agruparExpenseByDate(exps))
        let dsts = await getDistributions('All', units.filter(unit => unit.id_sigf === selectedUnit)[0].id)
        setDataDist(dsts)
        let plns = await getPlanifications('4', units.filter(unit => unit.id_sigf === selectedUnit)[0].id)
        setDataPlan(plns)
        console.log(plns)
    }

    const refilterData = () => {
        let filteredData = dataPaid;
        if (selectedsCourse.length > 0) {
            filteredData = filteredData.filter(item => selectedsCourse.includes(item.curso_nome));
        }
        if (selectedsPropina.length > 0) {
            filteredData = filteredData.filter(item => selectedsPropina.includes(item.designacao));
        }
        if (selectedsPayment.length > 0) {
            filteredData = filteredData.filter(item => selectedsPayment.includes(item.forma_pagamento));
        }
        setDataByDate(agruparPorDataPagamento(filteredData));
        setDataByCourse(agruporPorCurso(filteredData));
        setDataByReceita(agruparPorReceita(filteredData));
        setDataByPayment(agruparPorPagamento(filteredData));
        setTotalRevenue(filteredData.reduce((acc, row) => acc + row.valor, 0))
    }

    useEffect(() => {
        refilterData();
    }, [selectedsCourse, selectedsPropina, selectedsPayment]);

    const agruparPorDataPagamento = (dados) => {
        const agrupado = {};

        dados?.forEach(entrada => {
            const dataPagamento = entrada.data_pagamento;
            const valor = parseFloat(entrada.valor || 0);  // Supondo que há um campo 'valor'

            if (!agrupado[dataPagamento]) {
                agrupado[dataPagamento] = 0;
            }

            agrupado[dataPagamento] += valor;
        });

        const novoArray = Object.keys(agrupado).map(dataPagamento => ({
            data_pagamento: dataPagamento,
            total: agrupado[dataPagamento]
        }));

        console.log(novoArray);

        return novoArray;
    }

    const agruporPorCurso = (dados) => {
        const agrupado = {};

        dados.forEach(entrada => {
            const curso = entrada.curso_nome;
            const valor = parseFloat(entrada.valor || 0);  // Supondo que há um campo 'valor'

            if (!agrupado[curso]) {
                agrupado[curso] = 0;
            }

            agrupado[curso] += valor;
        });

        const novoArray = Object.keys(agrupado).map(curso => ({
            curso: curso,
            total: agrupado[curso]
        }));

        console.log(novoArray);

        return novoArray;
    }

    const agruparPorReceita = (dados) => {
        const agrupado = {};

        dados.forEach(entrada => {
            const receita = entrada.designacao;
            const valor = parseFloat(entrada.valor || 0);  // Supondo que há um campo 'valor'

            if (!agrupado[receita]) {
                agrupado[receita] = 0;
            }

            agrupado[receita] += valor;
        });

        const novoArray = Object.keys(agrupado).map(receita => ({
            receita: receita,
            total: agrupado[receita]
        }));

        console.log(novoArray);

        return novoArray;
    }

    const agruparPorPagamento = (dados) => {
        const agrupado = {};

        dados.forEach(entrada => {
            const pagamento = entrada.forma_pagamento;
            const valor = parseFloat(entrada.valor || 0);  // Supondo que há um campo 'valor'

            if (!agrupado[pagamento]) {
                agrupado[pagamento] = 0;
            }

            agrupado[pagamento] += valor;
        });

        const novoArray = Object.keys(agrupado).map(pagamento => ({
            pagamento: pagamento,
            total: agrupado[pagamento]
        }));

        console.log(novoArray);

        return novoArray;
    }

    const findCourses = (rows) => {
        const uniqueValues = [...new Set(rows?.map(row => row.curso_nome))];
        const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
        setTypeCourse(arrayWithIdAndName);
    };

    const findReceitas = (rows) => {
        const uniqueValues = [...new Set(rows?.map(row => row.designacao))];
        const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
        setTypePropina(arrayWithIdAndName);
    };

    const findPayments = (rows) => {
        const uniqueValues = [...new Set(rows?.map(row => row.forma_pagamento))];
        const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
        setTypePayment(arrayWithIdAndName);
    };

    const uniqueStudents = (rows) => {
        const uniqueValues = [...new Set(rows?.map(row => row.nome))];
        return uniqueValues.length;
    };

    const handleCourseChange = (value) => {
        setSelectedsCourse(value);
    }

    const handlePropinaChange = (value) => {
        setSelectedsPropina(value);
    }

    const handleSearch = () => {
        if (selectedUnit && selectedDateRange) {
            getData(selectedDateRange[0], selectedDateRange[1]);
        } else {
            notification.error({
                message: 'Erro',
                description: 'Por favor, selecione a unidade e o intervalo de datas',
            });
        }
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    //MEnu info
    const handleMenu = (menu) => {
        setSelectedMenu(menu);
    }

    //Despesa Info
    const [typeExpense, setTypeExpense] = useState([])
    const [tableExpense, setTableExpense] = useState([])
    const [modalExpense, setModalExpense] = useState(false);
    const [expense, setExpense] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);

    const handleExpense = (expense) => {
        setSelectedExpense(expense);
    }

    const refilterExpense = () => {
        let filteredData = dataExpense;
        if (selectedExpense?.length > 0) {
            filteredData = filteredData.filter(item => selectedExpense.includes(item.expense.nome))
        }
        setExpensesByDate(agruparExpenseByDate(filteredData))
        setExpensesByType(agruparExpenseByType(filteredData))
        setTableExpense(filteredData)
    }

    const sumExpenses = (dados) => {
        let valor = 0
        dados.forEach(exp => {
            valor += parseFloat(exp.value)
        })
        return valor
    }

    const findTypeExpense = (dados) => {
        const uniqueValues = [...new Set(dados?.map(row => row.expense.nome))];
        const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, nome: item }));
        setTypeExpense(arrayWithIdAndName);
    }

    useEffect(() => {
        console.log('changing')
        refilterExpense()
    }, [selectedExpense])

    const columnsExpense = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.nome, key: 'expense_type' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 50, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{ backgroundColor: 'blue' }} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                </div>
        }
    ];

    const [expensesByType, setExpensesByType] = useState([])
    const [expensesByDate, setExpensesByDate] = useState([])

    const agruparExpenseByDate = (dados) => {
        const agrupado = {};

        dados.forEach(entrada => {
            const dataPagamento = entrada.created_at.split('T')[0];
            const valor = parseFloat(entrada.value || 0);  // Supondo que há um campo 'valor'

            if (!agrupado[dataPagamento]) {
                agrupado[dataPagamento] = 0;
            }

            agrupado[dataPagamento] += valor;
        });

        const novoArray = Object.keys(agrupado).map(dataPagamento => ({
            data_pagamento: dataPagamento,
            total: agrupado[dataPagamento]
        }));

        console.log(novoArray);

        return novoArray;
    }

    const agruparExpenseByType = (dados) => {
        const agrupado = {}

        dados.forEach(entrada => {
            const exp = entrada.expense?.nome
            const valor = parseFloat(entrada.value || 0)

            if (!agrupado[exp]) {
                agrupado[exp] = 0
            }

            agrupado[exp] += valor
        })

        const novoArray = Object.keys(agrupado).map(exp => ({
            expense: exp,
            total: agrupado[exp].toFixed(2)
        }))
        return novoArray
    }

    const goSee = async (record) => {
        let data = await getExpense(record.id)
        setExpense(data)
        setModalExpense(true)
    }

    //Distribution info
    const [dataDist, setDataDist] = useState([])

    //Planification info
    const [dataPlan, setDataPlan] = useState([])

    return (
        <>
            <Spin spinning={loading}>
                <div className='w-full flex flex-col px-5 py-4'>
                    <p className='title-form'>Dashboard</p>
                    <div className='w-full grid grid-cols-1 sm:grid-cols-4 gap-2 mt-1'>
                        {type == 'all' ? (
                            <Select
                                showSearch
                                placeholder="Selecione a Unidade"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => setSelectedUnit(value)}
                                value={selectedUnit}
                            >
                                {units.map((unit, index) => (
                                    <Select.Option key={index} value={unit.id_sigf}>{unit.name}</Select.Option>
                                ))}
                            </Select>) : <>
                            <Input
                                value={units?.filter(unit => unit.id_sigf === selectedUnit)[0]?.name || 'Loading...'}
                                disabled
                                className='input-form'
                            />
                        </>
                        }
                        <DatePicker.RangePicker
                            style={{ margin: 0 }}
                            className='input-form'
                            value={selectedDateRange}
                            onChange={handleDateChange}
                        />
                        <Button
                            className='button-search'
                            onClick={handleSearch}
                        >
                            Pesquisar
                        </Button>
                    </div>
                    <div className='grid grid-cols-4 gap-3 mt-3 mb-2'>
                        <Statistic
                            title="Receitas Arrecadadas"
                            prefix="MZN"
                            value={totalRevenue}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            className="border border-gray-300 p-2 rounded-lg"
                        />
                        <Statistic
                            title="Despesas Executadas"
                            prefix="MZN"
                            value={sumExpenses(tableExpense)}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            className="border border-gray-300 p-2 rounded-lg"
                        />
                        <Statistic
                            title="Saldo Actual"
                            prefix="MZN"
                            value={unitBalance?.balance}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            className="border border-gray-300 p-2 rounded-lg"
                        />
                        <Statistic
                            title="Saldo Contabilístico"
                            prefix="MZN"
                            value={unitBalance?.pending}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            className="border border-gray-300 p-2 rounded-lg"
                        />
                    </div>
                    <div className='w-full flex flex-row'>
                        <div className='w-full'>
                            <div className='menu-bar mt-2'>
                                <div className={`menu-bar-item ${selectedMenu === 'revenue' ? 'active' : ''}`} onClick={() => handleMenu('revenue')}>
                                    <p className='menu-bar-item-text'>Receitas Arrecadadas</p>
                                </div>
                                <div className={`menu-bar-item ${selectedMenu === 'expense' ? 'active' : ''}`} onClick={() => handleMenu('expense')}>
                                    <p className='menu-bar-item-text'>Despesas</p>
                                </div>
                                <div className={`menu-bar-item ${selectedMenu === 'dist' ? 'active' : ''}`} onClick={() => handleMenu('dist')}>
                                    <p className='menu-bar-item-text'>Distribuições</p>
                                </div>
                                <div className={`menu-bar-item ${selectedMenu === 'plan' ? 'active' : ''}`} onClick={() => handleMenu('plan')}>
                                    <p className='menu-bar-item-text'>Planificações</p>
                                </div>
                            </div>
                            {selectedMenu === 'revenue' && (
                                <div className='w-full flex flex-col'>
                                    <div className='w-full flex-row'>
                                        <div className='w-full grid grid-cols-4 gap-2'>
                                            <Select
                                                mode="multiple"
                                                className="w-full"
                                                placeholder="Seleccione tipo(s) de Propina"
                                                value={selectedsPropina}
                                                onChange={handlePropinaChange}
                                                allowClear
                                            >
                                                {typePropina.map((item, index) => (
                                                    <Option key={index} value={item.name}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select
                                                mode="multiple"
                                                placeholder="Seleccione tipo(s) de Curso"
                                                value={selectedsCourse}
                                                onChange={handleCourseChange}
                                                allowClear
                                            >
                                                {typeCourse.map((item) => (
                                                    <Option key={item.id} value={item.name}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select
                                                mode="multiple"
                                                className="w-full"
                                                placeholder="Seleccione metodos(s) de Pagamento"
                                                value={selectedsPayment}
                                                onChange={(value) => setSelectedsPayment(value)}
                                                allowClear
                                            >
                                                {typePayment.map((item) => (
                                                    <Option key={item.id} value={item.name}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='w-full flex flex-row gap-4 mt-4'>
                                        <ResponsiveContainer className='w-1/2' width="50%" height={300}>
                                            <p className='w-full text-center title'>Receitas por Data</p>
                                            <AreaChart
                                                width={500}
                                                height={300}
                                                data={dataByDate}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="data_pagamento" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Area type="monotone" dataKey="total" stroke="#6CAEA8" fill="#6CAEA8" activeDot={{ r: 8 }} />
                                            </AreaChart>
                                        </ResponsiveContainer>

                                        <ResponsiveContainer className='w-1/2' width="50%" height={300}>
                                            <p className='w-full text-center title'>Receitas por Curso</p>
                                            <BarChart
                                                layout='horizontal'
                                                width={500}
                                                height={300}
                                                data={dataByCourse}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="curso" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="total" fill="#6CAEA8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className='w-full flex flex-row gap-4 mt-8'>
                                        <ResponsiveContainer className='w-1/2' width="50%" height={300}>
                                            <p className='w-full text-center title'>Receitas por Designação</p>
                                            <BarChart
                                                layout='horizontal'
                                                width={500}
                                                height={300}
                                                data={dataByReceita}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="receita" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="total" fill="#6CAEA8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <ResponsiveContainer className='w-1/2' width="50%" height={300}>
                                            <p className='w-full text-center title'>Receitas por Pagamento</p>
                                            <PieChart>
                                                <Pie
                                                    activeIndex={activeIndex}
                                                    activeShape={renderActiveShape}
                                                    data={dataByPayment}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#6CAEA8"
                                                    dataKey="total"
                                                    onMouseEnter={onPieEnter}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>

                                </div>
                            )}
                            {selectedMenu === 'expense' && (
                                <>
                                    <div className='w-full flex-row'>
                                        <div className='w-full grid grid-cols-4 gap-2'>
                                            <Select
                                                mode="multiple"
                                                className="w-full"
                                                placeholder="Seleccione a(s) Rubrica(s)"
                                                value={selectedExpense}
                                                onChange={handleExpense}
                                                allowClear
                                            >
                                                {typeExpense.map((item, index) => (
                                                    <Option key={index} value={item.nome}>
                                                        {item.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='w-full flex flex-row gap-4 mt-8'>
                                        <ResponsiveContainer width="50%" height={300}>
                                            <p className='w-full text-center title'>Execução por Data</p>
                                            <AreaChart
                                                width={500}
                                                height={300}
                                                data={expensesByDate}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="data_pagamento" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Area type="monotone" dataKey="total" stroke="#6CAEA8" fill="#6CAEA8" activeDot={{ r: 8 }} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                        <ResponsiveContainer className='w-1/2' width="50%" height={300}>
                                            <p className='w-full text-center title'>Execução por Designação</p>
                                            <BarChart
                                                layout='horizontal'
                                                width={500}
                                                height={300}
                                                data={expensesByType}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="expense" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="total" fill="#6CAEA8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className='w-full flex flex-row gap-2 mt-8'>
                                        <div className='w-2/3 flex flex-col'>
                                            <p className='title-form'>Despesas</p>
                                            <Table
                                                size='small'
                                                className='custom-table'
                                                columns={columnsExpense}
                                                dataSource={tableExpense}
                                                pagination={{ pageSize: 10 }}
                                                locale={{
                                                    emptyText: 'Sem processos de despesa'
                                                }}
                                            />
                                        </div>
                                        <div className='w-1/3 flex flex-col'>
                                            <p className='title-form'>Movimentos</p>
                                            <List
                                                size='small'
                                                itemLayout="horizontal"
                                                dataSource={moviments}
                                                renderItem={(item, index) => (
                                                    <List.Item>
                                                        <List.Item.Meta

                                                            avatar={item.type === 'Entrada' ? <FiTrendingUp style={{ fontSize: '20px', color: 'green' }} /> : <FiTrendingDown style={{ fontSize: '20px', color: 'red' }} />}
                                                            title={<a style={{ fontSize: '9pt' }}>{item.description}</a>}
                                                            description={<div className='w-full flex flex-row justify-between'><p className='text'>Saldo: {numeral(item.value).format('0,0.00')} MZN</p> <p className='text'>{dayjs(item.created_at).format('DD/MM/YYYY')}</p></div>}
                                                        />
                                                    </List.Item>
                                                )}
                                                pagination={{
                                                    pageSize: 5
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedMenu === 'dist' && (
                                <div className='w-full flex flex-row'>
                                    <div className='w-full'>
                                        <UnitDists dados={dataDist} />
                                    </div>
                                </div>
                            )}
                            {selectedMenu === 'plan' && (
                                <div className='w-full flex flex-row'>
                                    <div className='w-full'>
                                        <UnitPlans dados={dataPlan} saldo={unitBalance?.balance}/>
                                    </div>
                                </div>
                            )}
                            <Modal
                                title={<p className='title'>Detalhes da Despesa</p>}
                                visible={modalExpense}
                                onCancel={() => setModalExpense(false)}
                                footer={null}
                                width={700}
                                centered
                            >
                                <ExpenseDetail dados={expense} />
                            </Modal>
                        </div>
                    </div>

                </div>
            </Spin>
        </>
    );
};

export default Monitorar;